import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {saveTeamRegistrationData} from 'actions/registrationActions'
import {getSelectedTeam, getTeamPaymentHistory} from 'reducers/teamSelectors'
import timezones from 'timezones-list'

import {
    Grid, Container, Typography, Button, CircularProgress,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core'
import _ from "lodash/fp";
import {defaultTeam} from "./registrationUtils";
import PaymentProcessing from "./PaymentProcessing";
import TextField from "../common/TextInput";
import {useHistory} from "react-router-dom";
import Select from "../common/Select";
import {DEGREES, GENDERS, LEGAL_SYSTEMS, NATIONALITIES} from "../../utils/constants";
import {getAllSchools, getAllJurisdictions} from "../../reducers/globalSelectors";
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {getUser} from "../../actions/userActions";
import {getCurrentMoot,getIsTeamRegistrationOpen} from "../../reducers/mootSelectors";
import {PrintOutlined} from "@material-ui/icons";
import downloadInvoice from "../team/downloadInvoice";
import ordinal from 'ordinal-number-suffix'
import {format, utcToZonedTime} from "date-fns-tz";
import {asHKCurrency, shouldOverride} from "../helpers";
import InvoiceDetails from "./InvoiceDetails";
import {TEAM_MEMBER, TEAM_OWNER} from 'consts/roles';
import Unauthorized from "../Unauthorized";

const TeamRegistration = ({
                              existingTeamData,
                              getSelectedTeam = _.noop,
                              schools = [],
                              jurisdictions = [],
                              selectedTeam = {},
                              saveTeamRegistrationData = _.noop,
                              getAllSchools = _.noop,
                              getAllJurisdictions = _.noop,
                              getUser = _.noop,
                              getIsTeamRegistrationOpen = _.noop,
                              isTeamRegistrationOpen = {},
                              paymentOverride = false,
                              getTeamPaymentHistory = _.noop,
                              paymentHistory = [],
                              getCurrentMoot = _.noop,
                              currentMoot= {},
                              router,
                              user,
                          }) => {
    const history = useHistory()
    const overrideCode = router.location.query.override

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [pending, setPending] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [isUsingBankTransfer, setIsUsingBankTransfer] = useState(false)

    const [paymentInProcess, setPaymentInProcess] = useState(false)

    const [isPrintInvoiceDisabled, setIsPrintInvoiceDisabled] = useState(true);

    const defaultTeamData = !_.isEmpty(existingTeamData) ? existingTeamData : defaultTeam

    const [teamData, setTeamData] = useState(defaultTeamData)

    const timeZoneNames = _.map(x => x.name, timezones)

    useEffect(() => {
        const asyncFetchData = async () => {
            setIsLoading(true)
            try {
                await getCurrentMoot()
                await getIsTeamRegistrationOpen()
                const result = await getSelectedTeam()
                if (result?.id) {
                    await getTeamPaymentHistory(result?.id)
                }
                await getAllSchools()
                await getAllJurisdictions()
                if (!_.isEmpty(result)) {
                    setTeamData(result)
                }
                if (router.location.query.step) {
                    const resumeStep = parseInt(router.location.query.step)
                    setCurrentStep(resumeStep)
                }
            } catch (e) {
            }
            setIsLoading(false)
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam])

    // // Effect for print invoice form validation
    // useEffect(() => {
    //     const { useSchoolAddressForBilling, billingName, billingAddress1, billingCity, billingState, billingJurisdiction, billingPostalCode } = teamData;
    //
    //     if ((useSchoolAddressForBilling === undefined ? true : useSchoolAddressForBilling) || (billingName && billingAddress1 && billingCity && billingState && billingJurisdiction && billingPostalCode)) {
    //         setIsPrintInvoiceDisabled(false);
    //     } else {
    //         setIsPrintInvoiceDisabled(true);
    //     }
    //
    // }, [teamData]);

    const goBack = () => {
        const newStepNumber = currentStep - 1
        history.push(`${router.location.pathname}?step=${newStepNumber}${overrideCode ? `&override=${overrideCode}` : ''}`)
        setCurrentStep(newStepNumber)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const goForward = () => {
        const newStepNumber = currentStep + 1
        history.push(`${router.location.pathname}?step=${newStepNumber}${overrideCode ? `&override=${overrideCode}` : ''}`)
        setCurrentStep(newStepNumber)

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const save = async e => {
        e.preventDefault()
        try {
            setPending(true)
            const result = await saveTeamRegistrationData(teamData, overrideCode)
            if (result === null)
                throw new Error('data no saved')

            const team = await getSelectedTeam(true)
            await getTeamPaymentHistory(team.id)
            await getUser()
            setPending(false)
            if (currentStep < 3) {
                goForward()
            } else if (currentStep === 3) {
                history.replace('/team-owner')
            }

        } catch (e) {
            setError('Error saving registration. Please try again')
            setPending(false)
        }
    }


    const onPaymentTypeChanged = type => {
        if (type === 'bankTransfer') {
            setIsUsingBankTransfer(true)
        } else {
            setIsUsingBankTransfer(false)
        }
        setTeamData({
            ...teamData,
            paymentType: type
        })
    }

    //you must be a team owner or not a team member to be allowed to see this page
    const isAllowed = _.includes(TEAM_OWNER,user.roles) || !_.includes(TEAM_MEMBER,user.roles) 

    return (
        <Container style={{paddingBottom: '10rem'}}>
            {isLoading &&
                <CircularProgress size={35}/>
            }
            {!isLoading && !isAllowed &&
                <Unauthorized />
            }
            {!isLoading && isAllowed && (isTeamRegistrationOpen.notOpenYet === true && !shouldOverride(overrideCode,currentMoot)) &&
                <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                    <Grid item xs={12}>
                        <Typography component='h1' variant='h4'>Registration for Teams will open
                            on {format(utcToZonedTime(currentMoot?.teamRegistrationOpenDate, 'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')} (HKT)</Typography>
                    </Grid>
                </Grid>
            }
            {!isLoading && isAllowed && (isTeamRegistrationOpen.closed === true && !shouldOverride(overrideCode,currentMoot)) &&
                <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                    <Grid item xs={12}>
                        <Typography component='h1' variant='h4'>Registration for Teams is now closed</Typography>
                        {/*
                        <Typography component='h1' variant='h4'>Team Registration for the {ordinal(currentMoot?.mootNumber)} Vis East Moot will commence at {format(utcToZonedTime(isTeamRegistrationOpen.moot.teamRegistrationOpenDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')}(HKT)!</Typography>
                    */}
                    </Grid>
                </Grid>
            }
            {!isLoading && isAllowed && ((isTeamRegistrationOpen.notOpenYet === false && isTeamRegistrationOpen.closed === false) || shouldOverride(overrideCode,currentMoot)) &&
                <form onSubmit={save}>
                    <Grid container
                          style={{flexGrow: 1, padding: '20px'}}
                          spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Team Registration Step {currentStep} of 3</Typography>
                        </Grid>
                        {/*STEP 1*/}
                        {currentStep === 1 &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>We are excited to welcome back teams from all over for the {ordinal(currentMoot?.mootNumber)} Vis East Moot
                                        Competition, which will take place <strong>IN PERSON</strong> in Hong Kong. This means that all oral arguments will take place physically in Hong Kong. As with past in-person Vis East Moots, there will be a number of in-person events associated with the Vis East Moot, including the Opening Ceremony and Welcome Cocktail Reception, Assembly, and Awards Gala. There will also be various social networking events for both professionals and students.</Typography>
                                    <Typography variant='body1'>Registration fees for
                                        the {ordinal(currentMoot?.mootNumber)} Vis East Moot
                                        Competition will be as follows:
                                        <ul>
                                            <li><strong>Standard Registration Fee (4-8 team members physically participating in Hong Kong):</strong> {asHKCurrency(Math.abs(currentMoot?.inPersonRegistrationFee), 0)}.</li>
                                            <li><strong>Small Team Registration Fee (2-3 team members physically participating in Hong Kong):</strong> HK$ 9,500.</li>
                                            <li><strong>Additional Team Members (physically participating in Hong Kong):</strong> HK$ 1,500 each.
                                            </li>
                                        </ul>                                        
                                        The registration fee covers the Vis East Moot Competition and Vis East Moot sponsored events (unless otherwise advised), inclusive of non-transferrable Student Gala Lunch Tickets (4 for Standard Registration and 2 or 3 for Small Team Registration depending on the team composition). The registration fees are subject to certain conditions and details, which can be found in the {ordinal(currentMoot?.mootNumber)} VEM Rules, which are available on the Vis East Moot website (<a href="https://cisgmoot.org/" target="_blank" className="color-blue" rel="noreferrer">www.cisgmoot.org</a>).</Typography>
                                </Grid>

                                <Grid container item xs={12}
                                      style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                    <Typography variant="h6">School Information</Typography>
                                    <Grid item xs={12} style={{marginTop: "1rem"}}>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend' required>
                                                Are you authorized to register for the Vis East and act on behalf of
                                                the team?
                                            </FormLabel>
                                            <RadioGroup
                                                name='type'
                                                value={teamData.registrantAuthorized !== null && teamData.registrantAuthorized.toString()}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    registrantAuthorized: e.target.value === 'true'
                                                })}
                                                row>
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio color='primary' required/>}
                                                    label='Yes'
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio color='primary' required/>}
                                                    label='No'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {teamData.registrantAuthorized === false &&
                                            <Typography variant='body1' style={{color: 'red'}}>Only authorized persons
                                                may register on behalf of a team.</Typography>
                                        }
                                    </Grid>
                                    <Grid container item spacing={3} sx={12}>
                                        <Grid item xs={12} md={6} style={{margin: "1rem 0 2rem"}}>
                                            <Autocomplete
                                                onChange={(event, newValue) => setTeamData({
                                                    ...teamData,
                                                    school: newValue
                                                })}
                                                value={teamData.school || null}
                                                options={schools}
                                                freeSolo
                                                autoSelect
                                                renderInput={(params) => <MUITextField {...params}
                                                                                       inputProps={{...params.inputProps, pattern: "^[\\w\\d\\(\\)\\*\\.].*"}} variant="standard"
                                                                                       label="School" required/>}
                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} sx={12}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                value={teamData.schoolInNative || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    schoolInNative: e.target.value
                                                })}
                                                notRequired={true}
                                                name='school-native-alphabet'
                                                label='School in native alphabet'
                                                helperText='Example: Πανεπιστήμιο Ελλάδος'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                value={teamData.timeZone || null}
                                                onChange={(event, newValue) => setTeamData({
                                                    ...teamData,
                                                    timeZone: newValue
                                                })}
                                                options={timeZoneNames}
                                                renderInput={(params) => <MUITextField {...params}  inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}} variant="standard"
                                                                                       label={'Timezone'} required/>}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                value={teamData.street || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    street: e.target.value
                                                })}
                                                name='street'
                                                label='Address Line 1'
                                                helperText='Example: Banne Bikslaan 101C'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                value={teamData.unit || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    unit: e.target.value
                                                })}
                                                name='unit'
                                                label='Address Line 2'
                                                notRequired
                                                helperText='Example: 3rd floor'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name='city'
                                                value={teamData.city || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    city: e.target.value
                                                })}
                                                label='City'
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name='state'
                                                value={teamData.state || ''}
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    state: e.target.value
                                                })}
                                                label='State/Province'
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                onChange={(event, newValue) => setTeamData({
                                                    ...teamData,
                                                    jurisdiction: newValue
                                                })}
                                                value={teamData.jurisdiction}
                                                options={jurisdictions}
                                                freeSolo
                                                autoSelect
                                                renderInput={(params) => <MUITextField {...params} inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}} variant="standard"
                                                                                       label='Jurisdiction' required/>}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type='zip'
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    zip: e.target.value
                                                })}
                                                value={teamData.zip || ''}
                                                label='Postal Code'
                                                required
                                                helperText='If no postal code, enter "00000"'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Select
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    legalSystem: e.target.value
                                                })}
                                                value={teamData.legalSystem}
                                                options={LEGAL_SYSTEMS}
                                                label={'Legal System'}
                                                required
                                            />
                                        </Grid>
                                        {/*
                                        <Grid item xs={12} md={6}>
                                            <Select
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    attendance: e.target.value
                                                })}
                                                value={teamData.attendance}
                                                options={ATTENDANCES}
                                                label={'Attendance'}
                                                required
                                            />
                                        </Grid>
                                            */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend' required>
                                                How many previous Vis Moots or Vis East Moots has your school
                                                participated in?
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                onChange={e => setTeamData({
                                                    ...teamData,
                                                    previousMootCount: e.target.value
                                                })}
                                                value={teamData.previousMootCount || ''}
                                            >
                                                <FormControlLabel
                                                    value='0'
                                                    control={<Radio color='primary' required/>}
                                                    label='0'
                                                />
                                                <FormControlLabel
                                                    value='1-5'
                                                    control={<Radio color='primary' required/>}
                                                    label='1-5'
                                                />
                                                <FormControlLabel
                                                    value='6-10'
                                                    control={<Radio color='primary' required/>}
                                                    label='6-10'
                                                />
                                                <FormControlLabel
                                                    value='11+'
                                                    control={<Radio color='primary' required/>}
                                                    label='11+'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {currentStep === 2 &&
                            <Grid item xs={12}
                                  style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                <Typography variant="h6">Authorized Contact</Typography>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            What is your involvement with this team registration?
                                        </FormLabel>
                                        <RadioGroup
                                            name='type'
                                            value={teamData.registrantInvolvement || ''}
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantInvolvement: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value='participant'
                                                control={<Radio color='primary' required/>}
                                                label='Participant'
                                            />
                                            <FormControlLabel
                                                value='faculty'
                                                control={<Radio color='primary' required/>}
                                                label='Faculty/External Coach'
                                            />
                                            <FormControlLabel
                                                value='studentCoach'
                                                control={<Radio color='primary' required/>}
                                                label='Student Coach'
                                            />
                                            <FormControlLabel
                                                value='schoolAdministration'
                                                control={<Radio color='primary' required/>}
                                                label='School Administration'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {(teamData.registrantInvolvement === 'participant' ||
                                        teamData.registrantInvolvement === 'studentCoach') &&
                                    <>
                                        <Grid container item spacing={3} xs={12} style={{marginTop: "0"}}>
                                            <Grid item xs={12} md={6}>
                                                <Select
                                                    name={`degree`}
                                                    onChange={e => setTeamData({
                                                        ...teamData,
                                                        registrantDegree: e.target.value
                                                    })}
                                                    value={teamData.registrantDegree || ''}
                                                    options={DEGREES}
                                                    label={'Degree Pursued'}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={3} xs={12}>
                                            <Grid item xs={12} md={6}>
                                                <Select
                                                    name={`gender`}
                                                    onChange={e => setTeamData({
                                                        ...teamData,
                                                        registrantGender: e.target.value
                                                    })}
                                                    value={teamData.registrantGender}
                                                    options={GENDERS}
                                                    label={'Gender'}
                                                    required
                                                    helperText='*For statistical purposes only'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Autocomplete
                                                    onChange={(event, newValue) => setTeamData({
                                                        ...teamData,
                                                        registrantNationality: newValue
                                                    })}
                                                    value={teamData.registrantNationality}
                                                    options={NATIONALITIES}
                                                    freeSolo
                                                    autoSelect
                                                    required
                                                    renderInput={(params) => <MUITextField {...params}
                                                                                           inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                                           variant="standard"
                                                                                           label="Nationality"
                                                                                           required/>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Grid container item spacing={3} xs={12} style={{marginTop: "1rem"}}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantFirstName: e.target.value
                                            })}
                                            value={teamData.registrantFirstName}
                                            label='First Name (or equivalent)'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantLastName: e.target.value
                                            })}
                                            value={teamData.registrantLastName}
                                            label='Last Name'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantPhone: e.target.value
                                            })}
                                            value={teamData.registrantPhone}
                                            name='phone'
                                            label='Phone Number'
                                            helperText='Include (country code) (regional code) (local number)'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}/>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantStreet: e.target.value
                                            })}
                                            value={teamData.registrantStreet}
                                            label='Address Line 1'
                                            helperText='Example: Banne Bikslaan 101C'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantUnit: e.target.value
                                            })}
                                            value={teamData.registrantUnit}
                                            label='Address Line 2'
                                            notRequired
                                            helperText='Example: 3rd floor'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantCity: e.target.value
                                            })}
                                            value={teamData.registrantCity}
                                            label='City'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantState: e.target.value
                                            })}
                                            value={teamData.registrantState}
                                            label='State/Province'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            freeSolo
                                            autoSelect
                                            onChange={(event, newValue) => setTeamData({
                                                ...teamData,
                                                registrantJurisdiction: newValue
                                            })}
                                            value={teamData.registrantJurisdiction}
                                            options={jurisdictions}
                                            renderInput={(params) => <MUITextField {...params}
                                                                                   inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                                   variant="standard"
                                                                                   label="Jurisdiction" required/>}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setTeamData({
                                                ...teamData,
                                                registrantZip: e.target.value
                                            })}
                                            value={teamData.registrantZip}
                                            required
                                            label='Postal Code'
                                            helperText='If no postal code, enter "00000"'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormGroup style={{marginTop: '1rem'}} row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={teamData.hasAuthorityToAddTeamMembers}
                                                        onChange={e => setTeamData({
                                                            ...teamData,
                                                            hasAuthorityToAddTeamMembers: e.target.checked
                                                        })}
                                                        color='primary'
                                                        required
                                                    />
                                                }
                                                label={`I confirm that I have the authority to add team members and the team members’ personal information to the Team's account.`}
                                            />
                                        </FormGroup>
                                        <FormGroup style={{marginTop: '1rem'}} row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={teamData.acknowledgedVisEastUse}
                                                        onChange={e => setTeamData({
                                                            ...teamData,
                                                            acknowledgedVisEastUse: e.target.checked
                                                        })}
                                                        color='primary'
                                                        required
                                                    />
                                                }
                                                label={`By submitting this form, I acknowledge and consent to the Vis East's use of the submitted information for Vis East and Vis East related purposes.`}
                                            />
                                        </FormGroup>
                                    </Grid>

                                </Grid>
                            </Grid>
                        }
                        {currentStep === 3 &&
                            <>
                            <Grid item xs={12}
                                  style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                    <InvoiceDetails showPrintInvoice alwaysInEditMode paymentInProcess={paymentInProcess} onSetData={data => setTeamData(data)} />
                                    <Typography variant="h6" style={{marginTop: "3rem"}}>Payment</Typography>
                                    <Grid item xs={12} style={{marginTop: "1rem"}}>
                                        {_.isEmpty(selectedTeam.Payments) &&
                                            <>
                                                <Typography style={{marginBottom: '1rem'}}>Non-refundable
                                                    Deposit:</Typography>
                                                <Typography variant="h5" display={"inline"}>HK$ 1,500</Typography>
                                                <Typography style={{marginBottom: '1rem', marginTop: "1rem"}}>Teams are
                                                    responsible for ALL handling fees (bank fees and other payment
                                                    related charges). Please make sure that you include the charges in
                                                    the amount that you submit for payment, whether via PayPal, credit
                                                    card, or bank transfer, so that the Vis East Moot Administration
                                                    receives the full HK$ 1,500. As a kind reminder, fees will vary
                                                    depending on the payment method used. Teams are responsible for
                                                    determining the fees incurred.
                                                </Typography>
                                                <Typography variant='body1'>Please note that Applications will only be considered complete once the Vis East Moot Administration receives full payment of the Team's non-refundable deposit. For PayPal/Credit Card payments, Applications will be deemed completed once the payment is successfully processed. For bank transfers, Applications will be deemed complete once the Vis East Moot Administration confirms receipt of the funds. In this regard, please remember to include your <strong>School’s name</strong> in the
                                                    Reference/Message so that your payment is easily
                                                    trackable.</Typography>
                                                <Typography variant='body1'>Before submitting payment of your
                                                    non-refundable deposit, please ensure that all information contained
                                                    in your Application is accurate and complete.</Typography>
                                            </>
                                        }
                                        <PaymentProcessing paymentOverride={paymentOverride}
                                                           onPaymentTypeChanged={onPaymentTypeChanged}
                                                           transactionType='INITIAL'
                                                           onPaymentInProcess={() => {
                                                               setPaymentInProcess(true)
                                                           }}
                                                           onPaymentCompleted={async () => {
                                                               await saveTeamRegistrationData(teamData, overrideCode)
                                                               await getTeamPaymentHistory(selectedTeam.id, true)
                                                               setPaymentInProcess(false)
                                                           }}/>
                                    </Grid>
                            </Grid>
                            </>

                        }
                        <Grid item xs={12} style={{marginTop: "1rem", display: "flex", gap: "10px"}}>
                            {currentStep > 1 &&
                                <Button disabled={currentStep === 1} onClick={goBack} variant='contained'
                                        color="secondary">
                                    BACK
                                </Button>
                            }
                            {currentStep < 3 &&
                                <Button disabled={teamData.registrantAuthorized === false} type="submit"
                                        variant='contained' color="primary">
                                    NEXT
                                </Button>
                            }
                            {currentStep === 3 &&
                                <Button disabled={isUsingBankTransfer === false && _.isEmpty(selectedTeam.Payments)}
                                        type="submit" variant='contained' color="primary">
                                    {isUsingBankTransfer ? 'SUBMIT' : 'DONE'}
                                </Button>
                            }
                            {pending && <CircularProgress size={35}/>}
                            {error && <Typography color='error'>{error}</Typography>}
                        </Grid>
                    </Grid>
                </form>
            }
        </Container>
    )
}


export default connect(
    (state, ownProps) => ({
        router: state.router,
        existingTeamData: state.registration.teamData,
        schools: state.global.schoolNames,
        jurisdictions: state.global.jurisdictionNames,
        selectedTeam: state.team.selectedTeam,
        currentMoot: state.moot.currentMoot,
        isTeamRegistrationOpen: state.moot.currentMoot.isTeamRegistrationOpen,
        paymentOverride: ownProps.paymentOverride,
        paymentHistory: state.team.paymentHistory,
        user: state.user
    }), {
        saveTeamRegistrationData: saveTeamRegistrationData,
        getSelectedTeam: getSelectedTeam,
        getAllSchools: getAllSchools,
        getAllJurisdictions: getAllJurisdictions,
        getUser: getUser,
        getCurrentMoot: getCurrentMoot,
        getIsTeamRegistrationOpen: getIsTeamRegistrationOpen,
        getTeamPaymentHistory: getTeamPaymentHistory
    }
)(TeamRegistration)

import makeAction from "./actionCreator";
import * as Actions from "./actionTypes/registrationActionTypes";
import {createUserCognitoAccount} from "../utils/cognitoHelpers";
import {saveTeamRegistration,createUserAccount, saveArbitratorRegistration} from "api"
import {loginUser} from "./userActions";

export const signUpUser = (email,password) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.USER_SIGNUP_REQUESTED,{ email, password }))

    try {
        const cognitoId = await createUserCognitoAccount(email,password)
        if (!cognitoId)
            throw new Error("Cognito account not created")
        
        const user = await createUserAccount(email,cognitoId)

        //Auto login in the user after we create them
        await loginUser(email,password)(dispatch,getState)

        dispatch(makeAction(Actions.USER_SIGNUP_SUCCEEDED, user))
        return cognitoId;
    } catch (e) {
        dispatch(makeAction(Actions.USER_SIGNUP_FAILED, {},e))
        return null
    }
}


export const saveTeamRegistrationData = (data,overrideCode) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_TEAM_REGISTRATION_DATA_REQUESTED,{data,overrideCode}))

    try {
        const result = await saveTeamRegistration(data,overrideCode)
        dispatch(makeAction(Actions.SAVE_TEAM_REGISTRATION_DATA_SUCCEEDED, result))
        return result;
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_TEAM_REGISTRATION_DATA_FAILED, {},e))
        return null
    }
}

export const saveArbitratorRegistrationData = (data,overrideCode) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_ARBITRATOR_REGISTRATION_DATA_REQUESTED,{data,overrideCode}))

    try {
        const result = await saveArbitratorRegistration(data,overrideCode)
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_REGISTRATION_DATA_SUCCEEDED, result))
        return result;
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_REGISTRATION_DATA_FAILED, {},e))
        return null
    }
}

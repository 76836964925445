// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging,getToken,onMessage } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyByebvzdazFzt5TvGtiEdNglAsMn8sG7f0",
    authDomain: "viseasttesting.firebaseapp.com",
    projectId: "viseasttesting",
    storageBucket: "viseasttesting.firebasestorage.app",
    messagingSenderId: "1098056867334",
    appId: "1:1098056867334:web:d42b472a3a1c5439326d87",
    measurementId: "G-8N558YZKJ2"
};


const fbPublicKey = 'BOPZ8H8kPL7gIuNKfObNs6fKB8zCmbcJLuS6KhZ3eSv-0C2OfRdDsEhopP7YCHGeal_pXQ46laIEYQcqOeBwe38'
// Initialize Firebase
export const initializeFirebase = () => {
    const app = initializeApp(firebaseConfig);
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app);

    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    getToken(messaging, {vapidKey: fbPublicKey}).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log(`got token ${currentToken}`)
            // saveFirebaseToken(currentToken)
        } else {
            console.log('No registration token available.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);

        // show message in UI.
        // payload.notification.title
        // payload.notification.body
    })
}


export const requestNotificationPermission = () => {
    if (!("Notification" in window)) {
        console.log("Does not support notification permissions.");
    } else if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        console.log("Notification permission is already granted.");
    } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                console.log("Notification permission is granted.")
            }
        });
    }
}

